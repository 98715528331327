import React from 'react'
import { Link } from '@reach/router'

import me from '../../content/images/abel2023.jpg'
import dimo from '../../content/images/Walia.png'

export const AboutSidebar = () => {
  return (
    <aside className="post-sidebar">
      <div className="post-sidebar-card">
        <h2>Me</h2>
        <img src={me} alt="Abel" />
      </div>
      <div className="post-sidebar-card">
        <h2>walia Ibex (AI)</h2>
        <img src={dimo} alt="Abel" />
      </div>
    </aside>
  )
}
