export const projectsList = [
  {
    name: 'ML model classifying products',
    date: 'Mar - 2024',
    slug: 'ML-model-classifying-products',
    tagline: 'It prototype machine learning model created with FastAPI and TensorFlow. It efficiently classifies products into predefined categories, emphasizing rapid prototyping and fundamental machine learning principles',
    // url: 'https://nameless-tree-6864.fly.dev/',
    // writeup: '/02',
    highlight: false,
  },
  {
    name: 'Object Detection Microservice Deployment',
    date: 'Apr - 2024',
    slug: 'ML-Microservice-Deployment',
    tagline: 'FastAPI microservice for object detection. It offers streamlined deployment via Docker and CI/CD pipelines. Also provided are ECS task definitions, AWS CodeBuild setup, and GitHub Actions for deployment automation.',
    // url: 'https://nameless-tree-6864.fly.dev/',
    // writeup: '/02',
    highlight: true,
  },
  {
    name: 'RAG System for Contract Q&A',
    date: 'Feb - 2024',
    slug: 'Contract-Advisor-RAG-',
    tagline: 'Retrieval Augmented Generation (RAG) system tailored for Contract Q&A, merging language models with external data sources for precise and context-rich outputs.',
    url: 'https://pactwise.abelbekele.com/',
    writeup: 'https://medium.com/@abelbekele.addise/contract-advisor-rag-1f4110891dd8',
    highlight: true,
  },
  {
    name: 'Automated Storyboard Generator for Ad Campaigns',
    date: 'Feb - 2024',
    slug: 'Automated-Storyboard-Synthesis-for-Digital-Advertising',
    tagline: 'An AI-powered Automated Storyboard Generator translates textual ad concepts into visually compelling storyboards, leveraging NLP, computer vision, and image generation models for impactful campaigns.',
    // url: 'pactwise.abelbekele.com/',
    writeup: 'https://medium.com/@abelbekele.addise/automated-storyboard-generator-for-advertisement-campaigns-bea2111368f7',
    highlight: true,
  },
  {
    name: 'Refund by Location Smart-Contract',
    date: 'Feb - 2024',
    slug: 'Refund-by-Location-Smart-Contract',
    tagline: 'logistics through GPS and blockchain, this project introduces a decentralized carpooling platform powered by smart contracts. By connecting riders and drivers directly, it ensures privacy, security, and transparent transactions, setting a new standard for efficiency in tech-driven transportation solutions.',
    // url: 'pactwise.abelbekele.com/',
    writeup: 'https://medium.com/@abelbekele.addise/web3-geo-refund-protocol-streamlining-location-based-transactions-1c110ca39595',
    highlight: true,
  },
  {
    name: 'Amharic RAG Ad Builder',
    date: 'Jan - 2024',
    slug: 'Amharic-RAG-Ad-Builder',
    tagline: 'Amharic RAG Ad Builder is tailored for Telegram channels, it uses advanced Language Models to craft captivating and contextually relevant Amharic text advertisements. Revolutionize your advertising strategy with our cutting-edge solution designed for the Ethiopian market.',
    // url: 'pactwise.abelbekele.com/',
    writeup: 'https://medium.com/@abelbekele.addise/enabling-quality-embedding-and-text-generation-for-amharic-language-0248f76e6d8f',
    highlight: true,
  },
  {
    name: 'Streamlined LLM Prompt Engineering',
    date: 'Jan - 2024',
    slug: 'Precision-RAG',
    tagline: 'Streamlining Prompt Engineering and Enhancing User Interactions with Language Models (LLMs) through Automatic Prompt Generation, Evaluation Data Generation, and Prompt Testing and Ranking.',
    // url: 'pactwise.abelbekele.com/',
    writeup: 'https://medium.com/@abelbekele.addise/prompt-tuning-for-building-enterprise-grade-rag-systems-f0437281c26b',
    highlight: false,
  },
  {
    name: 'NFT Certificate Distribution with Algorand Blockchain',
    date: 'Jan - 2024',
    slug: 'End-to-End-Web3-dApps',
    tagline: 'Uses the Algorand Blockchain to create and distribute Non-Fungible Token (NFT) certificates for trainees. It ensures secure, tamper-proof certificate generation and allows stakeholders to verify authenticity through smart contracts. The project provides a simple user interface and robust security for managing and interacting with NFT certificates.',
    // url: 'pactwise.abelbekele.com/',
    writeup: 'https://medium.com/@abelbekele.addise/certificate-generation-distribution-and-value-transfer-with-algorand-nfts-and-smart-contracts-9e1e9cd66a41',
    highlight: false,
  },
  {
    name: 'LLM-Powered Data Visualizer using Redash',
    date: 'Jan - 2024',
    slug: 'Redash-chatbot-add-on',
    tagline: 'Transformative Redash add-on which integrates Flask, PostgreSQL, and OpenAIs GPT-3.5 Turbo for advanced data analytics with natural language processing and SQL translation. Empowering non-technical users to effortlessly fetch and visualize data using dynamic interactions and intelligent query generation.',
    // url: 'pactwise.abelbekele.com/',
    writeup: 'https://medium.com/@abelbekele.addise/redash-chatbot-add-on-9491b33cebbc',
    highlight: true,
  },
  {
    name: 'Traffic Data Analytics Data Warehouse',
    date: 'Dec - 2024',
    slug: 'Traffic-Data-Analytics-Data-Warehouse',
    tagline: 'A data warehouse for urban traffic analysis uses swarm UAVs and static cameras to collect traffic data. Leveraging ELT with DBT, it organizes and queries vehicle trajectory data to enhance traffic flow analysis and support downstream projects.',
    url: 'https://glittery-hummingbird-a6ce0a.netlify.app/',
    writeup: 'https://medium.com/@abelbekele.addise/data-warehouse-tech-stack-with-postgresql-dbt-airflow-and-redash-c86228dd94fd',
    highlight: false,
  },
  {
    name: 'Telecom User Behavior Analytics',
    date: 'Dec - 2024',
    slug: 'Telecom-User-Behavior-Analytics',
    tagline: 'A telecom analysis system for TellCo uses regression models to analyze user behavior, handset trends, and customer satisfaction. It examines session durations, application usage, and network performance to optimize telecom services and enhance customer experience.',
    url: 'https://10-academy-week-1-app.streamlit.app/',
    // writeup: 'https://medium.com/@abelbekele.addise/data-warehouse-tech-stack-with-postgresql-dbt-airflow-and-redash-c86228dd94fd',
    highlight: false,
  },
]